<template>
  <div>
    <!-- 动态生成筛选项 -->
    <div class="dynamicFiltering top">
      <!-- 关键词搜索 -->
      <nav class="strSearch">
        <div class="topCon">
          <span class="keyword" v-if="!$parent.isImgSearch"
            >{{ $fanyi("关键词") }}：{{
              $route.query.CN ? $route.query.keyword : $parent.keyWord
            }}
          </span>
          <el-image class="searchImg" :src="$route.query.imgUrl" v-else>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>

          <!-- <span class="quantity"
            >{{ $fanyi("共")
            }}<font>
              {{
                $parent.shopType == "1688"
                  ? $parent.total1688
                  : $parent.totalTaobao
              }} </font
            >{{ $fanyi("个产品") }}</span
          > -->
          <div class="crosswise">
            <div @click="$parent.Crosswise = true">
              <img
                v-if="!$parent.Crosswise"
                :src="require('@/assets/SearchResultsPage/vertical_1.png')"
                alt=""
              />
              <img
                v-else
                :src="require('@/assets/SearchResultsPage/vertical_0.png')"
                alt=""
              />
            </div>
            <div @click="$parent.Crosswise = false">
              <img
                v-if="$parent.Crosswise"
                :src="require('@/assets/SearchResultsPage/transverse_0.png')"
                alt=""
              />
              <img
                v-else
                :src="require('@/assets/SearchResultsPage/transverse_1.png')"
                alt=""
              />
            </div>
          </div>
        </div>
      </nav>

      <div v-if="false">
        <div
          class="row"
          v-for="(rowItem, rowIndex) in keySearchList"
          :key="rowIndex"
        >
          <label for="">{{ rowItem.translateName }}：</label>
          <div
            class="spanBox"
            v-for="(valItem, valIndex) in rowItem.children"
            :key="valIndex"
            :class="{
              active: find_owned_snId(rowItem.id, valItem.id),
            }"
            @click="chiose_snId(rowItem, valItem)"
          >
            {{ valItem.translateName }}
          </div>
        </div>
      </div>
    </div>
    <!-- 固定筛选项 -->
    <div class="dynamicFiltering">
      <div class="row">
        <label for="">{{ $fanyi("显赫") }} ：</label>
        <el-checkbox
          v-model="valueConTrol.yanXuanShangPin1688"
          @change="confirmChuanshen"
          >{{ $fanyi("1688严选商品") }}</el-checkbox
        >
        <el-checkbox
          v-model="valueConTrol.yanXuanGongChang1688"
          @change="confirmChuanshen"
          >{{ $fanyi("1688严选工厂") }}</el-checkbox
        >
        <div class="selectBox dipflex flexacenter">
          <div class="selectName">{{ $fanyi("店铺评分") }}:</div>
          <el-select
            v-model="valueConTrol.dianPuPinFen"
            placeholder=""
            @change="confirmChuanshen"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="switchBox dipflex flexacenter" style="margin-left:auto">
          <img :src="require('@/assets/allnewFile/cicon.svg')" alt="" />
          <img :src="require('@/assets/allnewFile/eicon.svg')" alt="" />
          <img
            :src="require('@/assets/allnewFile/maohao.svg')"
            alt=""
            style="margin-right:0"
          />
          <el-switch
            v-model="valueConTrol.ce"
            active-color="#1767D0"
            @change="confirmChuanshen"
            :active-text="$fanyi('与证书')"
            :inactive-text="$fanyi('查看')"
          >
          </el-switch>
        </div>
      </div>
      <div class="line"></div>
      <div class="row">
        <label for="">{{ $fanyi("条件") }} ：</label>
        <el-checkbox
          v-model="valueConTrol.qiTianWuLiYou"
          @change="confirmChuanshen"
          >{{ $fanyi("七天无理由退换") }}</el-checkbox
        >
        <el-checkbox
          v-model="valueConTrol.yiJinaQiFa"
          @change="confirmChuanshen"
          >{{ $fanyi("一件起发") }}</el-checkbox
        >
      </div>

      <div class="line"></div>
      <div class="row">
        <div
          class="sortingOptions"
          style="margin-right:unset;"
          :class="{ active: zonghe }"
          @click="setSortValue()"
        >
          <label>Ordenar por ：</label>
        </div>
        <div
          class="sortingOptions"
          :class="{ active: haveSort('monthSold') }"
          @click="setSortValue('monthSold')"
        >
          <span>{{ $fanyi("月销售总额") }}</span>
          <div class="sortIcon " :class="{ down: haveSort('monthSold') }"></div>
        </div>
        <div
          class="sortingOptions"
          :class="{ active: haveSort('rePurchaseRate') }"
          @click="setSortValue('rePurchaseRate')"
        >
          <span>{{ $fanyi("回购率") }}</span>
          <div
            class="sortIcon "
            :class="{ down: haveSort('rePurchaseRate') }"
          ></div>
        </div>
        <div
          class="sortingOptions"
          :class="{ active: haveSort('price') }"
          @click="setSortValue('price')"
        >
          <span>{{ $fanyi("单价排序") }}</span>
          <div
            class="sortIcon"
            :class="{ up: priceSort() == 'desc', down: priceSort() == 'asc' }"
          ></div>
        </div>
      </div>
      <div class="line"></div>
      <div class="row">
        <label>{{ $fanyi("价格范围") }} ：</label>

        <div class="priceRangeBox flex acenter">
          <span>{{ $fanyi("最低价格") }}</span>

          <div class="inputbox">
            <div class="shadow" v-if="!valueConTrol.priceStart">
              €<img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/668b984f4333f.svg"
                alt=""
              />
            </div>
            <el-input clearable v-model="valueConTrol.priceStart"></el-input>
          </div>

          <span class="segmentation">-</span>
          <div class="inputbox">
            <div class="shadow" v-if="!valueConTrol.priceEnd">
              €<img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/668f9c97de1e3.svg"
                alt=""
              />
            </div>
            <el-input clearable v-model="valueConTrol.priceEnd"></el-input>
          </div>
          <span>{{ $fanyi("最高价格") }}</span>
        </div>
        <div class="searchBtn">
          <el-button class="clearBtn" @click="clearChiose">
            {{ $fanyi("清理") }}
          </el-button>
          <el-button class="bgBlueBtn" @click="confirmChuanshen">{{
            $fanyi("确认")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valueConTrol: {
        yanXuanShangPin1688: false, //1688严选商品
        yanXuanGongChang1688: false, //1688严选工厂
        dianPuPinFen: "", //店铺评分
        qiTianWuLiYou: false, //七天无理由
        yiJinaQiFa: false, //一件起发
        sort: "", //排序字符串
        sortObj: [],
        priceStart: "", //起始价格
        priceEnd: "", //结束价格
        snId: {}, //动态搜索项
        ce: true, //ce筛选
      },
      zonghe: false,
      options: [
        {
          value: "",
          label: "Todo",
        },
        {
          value: "totalEpScoreLv1",
          label: "5★",
        },
        {
          value: "totalEpScoreLv2",
          label: "4.5★~5.0★",
        },
        {
          value: "totalEpScoreLv3",
          label: "4.0★~4.5★",
        },
      ],
      keySearchList: [],
    };
  },
  components: {},
  computed: {},
  created() {
    // this.getDynamicFilteringParameters();
    this.confirmChuanshen();
  },
  methods: {
    cechange(v) {
      this.valueConTrol.ce = [v];
      this.confirmChuanshen();
    },

    // 重置/清理
    clearChiose() {
      this.valueConTrol = {
        yanXuanShangPin1688: false, //1688严选商品
        yanXuanGongChang1688: false, //1688严选工厂
        dianPuPinFen: "", //店铺评分
        qiTianWuLiYou: false, //七天无理由
        yiJinaQiFa: false, //一件起发
        sort: "", //排序字符串
        sortObj: [],
        priceStart: "", //起始价格
        priceEnd: "", //结束价格
        snId: {}, //动态搜索项
        ce: true,
      };
      this.confirmChuanshen();
    },

    // 获取动态筛选参数
    getDynamicFilteringParameters() {
      this.$api
        .keywordSNQuery({
          keyword: this.$route.query.keyword,
        })
        .then((res) => {
          ////console.log('事件名',res)
          if (res.code != 0)
            return this.$message.error(this.$fanyi(res.data.msg));

          //接下来的操作
          res.data.forEach((data) => {
            data.children.forEach((element) => {
              let id = element.id;
              id = id.split(":");
              element.id = id[1];
            });
          });

          this.keySearchList = res.data;
        });
    },
    // 图标展示判断
    priceSort() {
      if (this.valueConTrol.sort.indexOf("price") != -1) {
        if (this.valueConTrol.sort.indexOf("desc") != -1) {
          return "desc";
        } else {
          return "asc";
        }
      } else {
        return "";
      }
    },

    // 设置sort参数
    setSortValue(name) {
      if (!name) {
        this.valueConTrol.sort = "";
        this.zonghe = true;
      } else if (name == "price") {
        this.zonghe = false;
        if (this.valueConTrol.sort.indexOf("desc") != -1) {
          this.valueConTrol.sort = `{${name}:'asc'}`;
          this.valueConTrol.sortObj = [
            {
              key: name,
              value: "asc",
            },
          ];
        } else {
          this.valueConTrol.sort = `{${name}:'desc'}`;
          this.valueConTrol.sortObj = [
            {
              key: name,
              value: "desc",
            },
          ];
        }
      } else {
        this.zonghe = false;
        this.valueConTrol.sort = `{${name}:'desc'}`;
        this.valueConTrol.sortObj = [
          {
            key: name,
            value: "asc",
          },
        ];
      }
      this.confirmChuanshen();
      this.$forceUpdate();
    },

    // 判断sort是否有已选
    haveSort(name) {
      if (this.valueConTrol.sort.indexOf(name) != -1) {
        return true;
      } else {
        return false;
      }
    },

    // 拼凑传参
    confirmChuanshen() {
      let searchData = {};
      if (this.$route.query.type == "imgSearch") {
        // console.log(JSON.parse(this.valueConTrol.sort));

        searchData.order_by = this.valueConTrol.sortObj;
        searchData.priceMin = this.valueConTrol.priceStart;
        searchData.priceMax = this.valueConTrol.priceEnd;

        let filter = [];
        if (this.valueConTrol.yanXuanShangPin1688) {
          filter.push("jxhy");
        }
        if (this.valueConTrol.yanXuanGongChang1688) {
          filter.push("certifiedFactory");
        }
        if (this.valueConTrol.dianPuPinFen) {
          filter.push(this.valueConTrol.dianPuPinFen);
        }
        if (this.valueConTrol.qiTianWuLiYou) {
          filter.push("noReason7DReturn");
        }
        if (this.valueConTrol.yiJinaQiFa) {
          filter.push("isOnePsale");
        }
      } else {
        let filter = [];
        if (this.valueConTrol.yanXuanShangPin1688) {
          filter.push("jxhy");
        }
        if (this.valueConTrol.yanXuanGongChang1688) {
          filter.push("certifiedFactory");
        }
        if (this.valueConTrol.dianPuPinFen) {
          filter.push(this.valueConTrol.dianPuPinFen);
        }
        if (this.valueConTrol.qiTianWuLiYou) {
          filter.push("noReason7DReturn");
        }
        if (this.valueConTrol.yiJinaQiFa) {
          filter.push("isOnePsale");
        }

        let snidStr = [];
        Object.keys(this.valueConTrol.snId).forEach((key) => {
          if (this.valueConTrol.snId[key]) {
            snidStr.push(key + ":" + this.valueConTrol.snId[key]);
          }
        });
        snidStr = snidStr.join(";");
        searchData = {
          filter: filter.join(","),
          sort: this.valueConTrol.sort,
          priceStart: this.valueConTrol.priceStart,
          priceEnd: this.valueConTrol.priceEnd,
          snId: snidStr,
          productCollectionId: Number(this.valueConTrol.ce),
        };
      }
      this.$emit("filterData", searchData);
    },

    find_owned_snId(name, v) {
      // console.log(this.valueConTrol.snId);
      if (
        this.valueConTrol.snId[name] != undefined &&
        this.valueConTrol.snId[name] == v
      ) {
        return true;
      } else {
        return false;
      }
    },
    chiose_snId(rowItem, valItem) {
      if (
        this.valueConTrol.snId[rowItem.id] &&
        this.valueConTrol.snId[rowItem.id] == valItem.id
      ) {
        this.valueConTrol.snId[rowItem.id] = "";
      } else {
        this.valueConTrol.snId[rowItem.id] = valItem.id;
      }
      this.$forceUpdate();
      this.confirmChuanshen();
      // console.log(this.valueConTrol.snId);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.dynamicFiltering {
  box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
  background-color: white;
  margin: 0 auto 20px;
  width: 1400px;
  padding: 20px 32px;
  border-radius: 10px;
  .row {
    height: 36px;
    display: flex;
    align-items: center;
    gap: 24px;
    &:last-child {
      border-bottom: none;
    }
    label {
      font-size: 14px;
      font-weight: 600;
      color: #475467;
    }
    .cetitle {
      @extend .dip;
      img {
        margin-right: 6px;
      }
    }
    .spanBox {
      margin-right: 20px;
      padding: 3px 10px;
      border-radius: 4px;
      cursor: pointer;
    }
    .spanBox.active {
      background-color: #1e2997;
      color: white;
    }
    .el-checkbox {
      margin-right: 0px;
      /deep/.el-checkbox__label {
        font-weight: 400;
        font-size: 14px;
      }
    }
    .el-checkbox.is-checked {
      /deep/.el-checkbox__inner {
        background-color: #1767d0;
        border-color: #1767d0;
      }
      /deep/.el-checkbox__label {
        color: #1767d0;
      }
    }
    .sortingOptions {
      color: #475467;
      cursor: pointer;
      display: flex;
      label {
        font-weight: 600;
        font-size: 14px;
      }
      span {
        margin-right: 5px;
        font-size: 14px;
      }

      .sortIcon {
        width: 24px;
        height: 24px;
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684f47b49488.svg");
        background-size: 100% 100%;
      }
      .down {
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684fe7856e26.png");
        transform: rotate(180deg); /* 旋转90度 */
      }
      .up {
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684fe7856e26.png");
      }
    }
    .sortingOptions.active {
      color: #1767d0;
    }

    .priceRangeBox {
      margin-right: 24px;
      .inputbox {
        position: relative;
        .el-input {
          width: 60px;
          /deep/.el-input__inner {
            height: 36px;
            background-color: transparent;
            padding: 0 10px;
          }
          /deep/.el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .shadow {
          position: absolute;
          width: 60px;
          height: 36px;
          color: var(--Gray-300, #d0d5dd);
          font-size: 18px;
          font-weight: 600;
          @extend .dip;
        }
      }
      span {
        font-size: 14px;
        color: #475467;
        &:first-child {
          margin-right: 8px;
        }
        &:last-child {
          margin-left: 8px;
        }
      }

      .segmentation {
        margin: 5px;
      }
    }
    .searchBtn {
      margin-left: auto;
      .el-button {
        height: 44px;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 700;
      }
      .clearBtn {
        color: var(--Rakumart-700, #1355ab);
        width: 100px;
        margin-right: 24px;
        border: none;
      }
      .bgBlueBtn {
        margin-left: 0;
        width: 180px;
      }
    }
    .selectBox {
      .selectName {
        font-size: 14px;
        margin-right: 16px;
        color: #475467;
      }
      .el-select {
        /deep/.el-input__inner {
          border-radius: 8px;
          width: 111px;
          height: 36px;
        }
        /deep/.el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .switchBox {
      img {
        margin-right: 6px;
      }
      .el-switch {
        /deep/.el-switch__label {
          font-size: 14px;
          color: #98a2b3;
        }
        /deep/.el-switch__label.is-active {
          color: #1767d0;
        }
        /deep/.el-switch__label--left {
          margin-right: 8px;
        }
        /deep/.el-switch__label--right {
          margin-right: 8px;
        }
      }
    }
  }
  .line {
    border-top: 1px dashed #dfdfdf;
    margin: 12px 0;
  }
}
.dynamicFiltering.top {
  padding: 0 32px;
}
.el-checkbox {
  /deep/.el-checkbox__original {
    display: none;
  }
}
nav {
  height: 80px;
  // border-bottom: dashed 1px #dfdfdf;
  // 切换显示商品形式
  .crosswise {
    display: flex;
    margin-left: auto;
    cursor: pointer;

    > div {
      height: 40px;

      &:first-child {
        margin-right: 20px;
      }
    }

    img {
      $searchImgSize: 40px;
      width: $searchImgSize;
      height: $searchImgSize;
    }
  }
}
nav.strSearch {
  height: 80px;
  // background-color: white;
  // box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
  // border-radius: 4px;
  // padding: 0 20px 20px 20px;
  // width: $pageWidth;
  // margin: 30px auto 30px;
  position: relative;
  left: 0px;

  > div {
    display: flex;
  }

  .topCon {
    padding: 20px 0 0;
    display: flex;
    align-items: center;

    .keyword {
      // margin: 0 10px;
    }

    .query {
      color: $homePageSubjectColor;
    }

    .quantity {
      margin-left: auto;

      font {
        color: #ffa018;
      }
    }
  }
  /deep/.searchImg {
    border-radius: 10px;
    border: 1px solid rgba(#1e2997, 0.3);
    $imgSize: 50px;
    width: $imgSize;
    height: $imgSize;
    transition: 0.3s;
    text-align: center;

    i {
      font-size: $imgSize/4;
      line-height: $imgSize;
    }
  }

  .bottomCon {
    display: flex;
    height: 60px;
    align-items: center;

    .composeType {
      padding: 0 20px;
      width: 100%;
      height: 60px;
      background: #f0f0f0;
      border-radius: 10px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #000000;
      line-height: 24px;

      .title {
        margin-right: 80px;
        cursor: pointer;
      }

      > div {
        display: flex;
        align-items: center;
      }

      .salesVolume {
        margin-right: 80px;

        .el-select {
          width: 160px;
          height: 30px;
          border-radius: 6px;
        }

        /deep/.el-input__inner {
          width: 160px;
          height: 30px;
          border-radius: 6px;
          border: 1px solid rgba(#1e2997, 0.3);
        }

        /deep/.el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .priceSection {
        display: flex;
        align-items: center;

        .fg {
          margin: 0 10px;
          width: 21px;
          height: 1px;
          background-color: black;
        }

        .inputBox {
          display: flex;
          align-items: center;
          width: 120px;
          height: 30px;
          border-radius: 6px;
          background-color: white;
          border: 1px solid rgba(#1e2997, 0.3);

          span {
            width: 10px;
            height: 20px;
            font-size: 14px;

            color: #000000;
            line-height: 20px;
            margin-left: 10px;
          }

          input {
            border: none;
            outline: none;
            width: 100%;
            padding: 0 3px;
          }
        }
      }
    }
  }
}
</style>
